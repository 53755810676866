<template>
  <div class="feedback-page">
    <button @click="goBack" class="back-button">
        <i class="fas fa-arrow-left"></i> Zurück
      </button>
    <h1>Kennzeichen sperren</h1>
    <form @submit.prevent="handleSubmit">


      <div class="form-group">
        <div class="input-icon" :class="{ 'error-border': isSubmitted && errors.email }">
          <i class="fas fa-envelope"></i>
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder="E-Mail-Adresse"
          />
        </div>
      </div>


      <div class="form-group">
        <div class="username-container input-icon" :class="{ 'error-border': isSubmitted && errors.username }">
          <span class="at-symbol">@</span>
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="Benutzername"
          />
        </div>
      </div>


      <div class="form-group">
        <div class="input-icon" :class="{ 'error-border': isSubmitted && errors.cityCode }">
          <i class="fas fa-city"></i>
          <input
            type="text"
            id="cityCode"
            v-model="cityCode"
            maxlength="3"
            placeholder="Stadtkennung (z.B. HSK)"
            @blur="checkIfPlateExists"
            @input="validateAlphaInput"
          />
        </div>
      </div>


      <div class="form-group">
        <div class="input-icon" :class="{ 'error-border': isSubmitted && errors.letters }">
          <i class="fas fa-font"></i>
          <input
            type="text"
            id="letters"
            v-model="letters"
            maxlength="2"
            placeholder="Buchstaben (z.B. AB)"
            @blur="checkIfPlateExists"
            @input="validateAlphaInput"
          />
        </div>
      </div>


      <div class="form-group">
        <div class="input-icon" :class="{ 'error-border': isSubmitted && errors.numbers }">
          <i class="fas fa-hashtag"></i>
          <input
            type="text"
            id="numbers"
            v-model="numbers"
            maxlength="4"
            placeholder="Zahlenreihe (z.B. 1234)"
            @input="(event) => { validateNumbersInput(event); checkIfPlateExists(); }"
          />
        </div>
      </div>


      <p class="info-message">
        Kennzeichen: {{ formattedLicensePlate }}
      </p>


      <p v-if="plateCheckError || showPlateExistsWarning" class="warning-message">
        <span v-if="plateCheckError">{{ plateCheckError }}</span>
        <span v-else>Das Kennzeichen {{ formattedLicensePlate }} ist bereits gesperrt.</span>
      </p>


      <div class="switch-group">
        <div class="switch-item" :class="{ 'error-border': isSubmitted && errors.allowContact }">
          <div class="label-container">
            <label>Kontaktaufnahme per E-Mail erlauben</label>
          </div>
          <label class="switch">
            <input type="checkbox" v-model="allowContact" />
            <span class="slider"></span>
          </label>
        </div>


        <div class="switch-item" :class="{ 'error-border': isSubmitted && errors.privacyConsent }">
          <div class="label-container">
            <label>
              Ich stimme der Verarbeitung meiner Daten gemäß der 
              <a href="/datenschutz" target="_blank">Datenschutzerklärung</a> zu.
            </label>
          </div>
          <label class="switch">
            <input type="checkbox" v-model="privacyConsent" />
            <span class="slider"></span>
          </label>
        </div>
      </div>

      <button 
        type="submit" 
        class="submit-button" 
        :disabled="!isFormValid() || plateExists"
      >Absenden</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      username: '',
      cityCode: '',
      letters: '',
      numbers: '',
      allowContact: false,
      privacyConsent: false,
      errors: {},
      isSubmitted: false,
      plateExists: false,
      plateCheckError: '' // Neue Variable für spezifische Fehlermeldungen
    };
  },
  computed: {
    formattedLicensePlate() {
      return `${this.cityCode.toUpperCase()}-${this.letters.toUpperCase()}-${this.numbers}`;
    },
    showPlateExistsWarning() {
      return this.plateExists && this.cityCode && this.letters && this.numbers;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Eine Seite zurück
    },
  validateAlphaInput(event) {
    const value = event.target.value;
    event.target.value = value.replace(/[^A-Za-z]/g, '');
    this[event.target.id] = event.target.value;
  },
  validateNumbersInput(event) {
    const value = event.target.value;
    event.target.value = value.replace(/[^0-9]/g, '');
    this.numbers = event.target.value;
  },
  async checkIfPlateExists() {
    this.plateCheckError = '';

    if (this.cityCode && this.letters && this.numbers) {
      const fullPlate = `${this.cityCode.toUpperCase()}${this.letters.toUpperCase()}${this.numbers}`;
      
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/check-plate`, { fullPlate });
        this.plateExists = response.data.exists;
      } catch (error) {
        this.plateCheckError = 'Fehler bei der Überprüfung des Kennzeichens';
        this.plateExists = false;
      }
    } else {
      this.plateCheckError = '';
      this.plateExists = false;
    }
  },
  validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  },
  isFormValid() {
    return (
      this.email &&
      this.validateEmail(this.email) &&
      this.username.length >= 3 &&
      this.username.length <= 30 &&
      /^[A-Za-z]{1,3}$/.test(this.cityCode) &&
      /^[A-Za-z]{1,2}$/.test(this.letters) &&
      /^\d{1,4}$/.test(this.numbers) &&
      this.allowContact &&
      this.privacyConsent
    );
  },
  async handleSubmit() {
    this.isSubmitted = true;
    this.errors = {};

    if (this.isFormValid() && !this.plateExists) {
      try {
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/create-request`, {
          email: this.email,
          username: this.username,
          cityCode: this.cityCode,
          letters: this.letters,
          numbers: this.numbers,
          type: 'RAW_INPUT_BLOCKED_PLATE'
        });
        
        // Speichern der Daten im localStorage
        localStorage.setItem('licensePlate', this.formattedLicensePlate);
        localStorage.setItem('username', this.username);
        localStorage.setItem('email', this.email);
        
        // Weiterleitung zur Bestätigungsseite
        this.$router.push({ name: 'SubmittedBlockedPlate' });
      } catch (error) {
        this.errors.backend = error.response?.data?.message || 'Fehler beim Senden der Anfrage';
      }
    }
  }
}

};
</script>

<style scoped>
.feedback-page {
  max-width: 500px;
  margin: 40px auto;
  padding: 30px;
  color: #f0f0f0;
}

h1 {
  text-align: center;
  font-size: 2em;
  color: #4ade80;
}

.form-group {
  margin-bottom: 20px;
}

.input-icon {
  position: relative;
}

.input-icon i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #a0a0a0;
}

.info-message {
  font-size: 1em;
  color: #cccccc;
  line-height: 1.6;
  margin-bottom: 20px; /* Abstand unter dem Kennzeichen-Text */
  text-align: center;
}

input[type="text"],
input[type="email"] {
  padding: 10px 10px 10px 35px;
  font-size: 1em;
  background-color: #2a2a2e;
  color: #f0f0f0;
  border-radius: 8px;
  border: 1px solid #333;
  width: 100%;
}

.warning-message {
  font-size: 1em;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #ffdddd;
  border: 1px solid #ff5c5c;
  border-radius: 8px;
  color: #b30000;
  font-weight: bold;
}



.info-message {
  font-size: 1.1em;
  text-align: center;
  color: #cccccc;
  margin-top: 15px;
}

.warning-message {
  font-size: 1em;
  text-align: center;
  color: #ff5c5c;
  margin-top: 5px;
  font-weight: bold;
}

.submit-button {
  width: 100%;
  padding: 15px;
  font-size: 1.1em;
  background-color: #4ade80;
  color: #1c1c1e;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #888888;
  cursor: not-allowed;
}

</style>