// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';

import HomePage from '../components/HomePage.vue';
import CreateRequestPage from '../components/CreateRequestPage.vue';
import NotFoundPage from '../components/NotFoundPage.vue';
import SelectionPage from '../components/SelectionPage.vue';
import SubmittedRequestPage from '../components/SubmittedRequestPage.vue';
import AnswerRequestPage from '@/components/AnswerRequestPage.vue';
import SubmittedAnswerRequestPage from '../components/SubmittedAnswerRequestPage.vue';
import BlockPlatePage from '../components/BlockPlatePage.vue';
import SubmittedBlockedPlate from '../components/SubmittedBlockedPlate.vue';
import ContactPage from '../components/ContactPage.vue';
import PrivacyPolicyPage from '../components/PrivacyPage.vue';
import RequestConfirmationPage from '@/views/RequestConfirmationPage.vue';
import RulesPage from '../components/RulesPage.vue';
import SpotsOverviewPage from '@/components/SpotsOverviewPage.vue';

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/start', name: 'SelectionPage', component: SelectionPage },
  { path: '/spot-create', component: CreateRequestPage }, // Neue Route hinzufügen
  {
    path: '/spot-create-d',
    name: 'SubmittedRequestPage',
    component: SubmittedRequestPage,
    props: (route) => ({
      request: {
        email: route.query.email,
        username: route.query.username,
        description: route.query.description,
        timestamp: route.query.timestamp,
      }
    })
  },
  { path: '/spot-request/:spotId?', name: 'AnswerRequestPage', component: AnswerRequestPage }, // Neue Route hinzufügen
  {
    path: '/spot-request-d',
    name: 'SubmittedAnswerRequestPage',
    component: SubmittedAnswerRequestPage,
    beforeEnter: (to, from, next) => {
      // Check for submission success flag
      if (localStorage.getItem('submissionSuccess') === 'true') {
        next(); // Allow access
      } else {
        // Redirect to the form page if flag is not set
        next({ name: 'HomePage' });
      }
    }
  },
  { path: '/block-plate', name: 'BlockPlatePage', component: BlockPlatePage },
  {
    path: '/block-plate-d',
    name: 'SubmittedBlockedPlate',
    component: SubmittedBlockedPlate,
    beforeEnter: (to, from, next) => {
      // Prüfen, ob ein Kennzeichen im localStorage vorhanden ist
      if (localStorage.getItem('licensePlate')) {
        next(); // Zugriff erlauben
      } else {
        // Umleitung zur Startseite, falls kein Kennzeichen gesetzt ist
        next({ name: 'HomePage' });
      }
    }
  },
  {
    path: '/confirm',
    name: 'RequestConfirmationPage',
    component: RequestConfirmationPage,
    beforeEnter: async (to, from, next) => {
      const token = to.query.token;

    // Wenn kein Token vorhanden ist oder es leer ist (z. B. `token=`), zur Fehlerseite weiterleiten
    if (!token || token.trim() === '') {
      next({ name: 'HomePage' }); // zur Fehlerseite weiterleiten
      return;
    } else {
      next(); // Zugriff erlauben
    }
    }
  },
  { path: '/rules', name: 'RulesPage', component: RulesPage },
  { path: '/spots', name: 'SpotsOverviewPage', component: SpotsOverviewPage },
  { path: '/impressum', name: 'ImpressumPage', component: ContactPage },
  { path: '/datenschutz', name: 'PrivacyPolicyPage', component: PrivacyPolicyPage }, 
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundPage',
    component: NotFoundPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;