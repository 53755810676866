<template>
  <div v-if="request" class="submitted-request">
    <div class="request-header">
      <h1>Eingereichte Anfrage</h1>
      <span class="submission-date">{{ formatTimestamp(request.timestamp) }}</span>
    </div>

    <div class="request-content">
      <div class="info-section">
        <div class="info-item">
          <label>Benutzername</label>
          <p>@{{ request.username }}</p>
        </div>
        <div class="info-item">
          <label>E-Mail-Adresse</label>
          <p>{{ request.email }}</p>
        </div>
      </div>

      <div class="description-section">
        <label>Beschreibung</label>
        <p class="description-text">{{ request.description }}</p>
      </div>

      <!-- Weiterleitung akzeptiert oder nicht -->
      <div class="forwarding-status">
  <i
    :class="request.allowUsername ? 'fas fa-check-circle accepted' : 'fas fa-times-circle not-accepted'"
  ></i>
  <span>
    {{ request.allowUsername ? 'Weiterleitung akzeptiert' : 'Weiterleitung nicht akzeptiert' }}
  </span>
</div>


      <!-- Hervorgehobene Informationsnachricht -->
      <div class="confirmation-warning">
        <i class="fas fa-exclamation-circle"></i>
        <p>
          Bitte bestätige diese Anfrage per E-Mail. Nach der Bestätigung wird die Anfrage erneut überprüft und dann automatisiert auf Instagram hochgeladen. 
          Falls diese Anfrage zurückgerufen werden soll, sende bitte eine E-Mail an <a href="mailto:support@spottedhsk.de">support@spottedhsk.de</a>.
        </p>
      </div>

      <!-- Button-Gruppe für Aktionen -->
      <div class="actions">
        <router-link to="/spot-create">
          <button class="action-button green-button">Neue Anfrage erstellen</button>
        </router-link>
        <router-link to="/start">
          <button class="action-button gray-button">Zurück</button>
        </router-link>
      </div>
    </div>
  </div>

  <div v-else class="error-message">
    <p>Die Anfrage konnte nicht geladen werden. Bitte versuche es erneut.</p>
  </div>
</template>

<script>
export default {
  name: 'SubmittedRequestPage',
  data() {
    return {
      request: null,
    };
  },
  mounted() {
    const requestData = localStorage.getItem('submittedRequest');
    if (requestData) {
      this.request = JSON.parse(requestData);
      localStorage.removeItem('submittedRequest'); // Daten nach dem Laden entfernen
    } else {
      // Falls keine Daten vorhanden sind, z.B. wenn die Seite direkt aufgerufen wurde
      this.$router.push('/'); // Zurück zur Startseite navigieren
    }
  },
  methods: {
    formatTimestamp(timestamp) {
      if (!timestamp) return 'Kein Zeitstempel verfügbar';
      const date = new Date(timestamp);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    }
  },
};
</script>

<style scoped>
.submitted-request {
  max-width: 500px;
  width: 90%; /* Anpassung für mobile Geräte */
  margin: 20px auto;
  padding: 20px;
  color: #f0f0f0;
  font-family: Arial, sans-serif;
}

.request-header {
  text-align: center;
  border-bottom: 1px solid #333;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.request-header h1 {
  font-size: 1.8em;
  color: #4ade80;
  margin: 0;
}
.forwarding-status {
  display: flex;
  align-items: center; /* Zentriert Icon und Text vertikal */
  gap: 10px; /* Abstand zwischen Icon und Text */
  background-color: #2a2a2e;
  padding: 15px;
  border-radius: 8px;
  text-align: left; /* Text bleibt linksbündig */
  justify-content: center; /* Zentriert den gesamten Inhalt horizontal */
}



.accepted {
  color: #4ade80 !important; /* Grün für akzeptiert */
}

.not-accepted {
  color: #ff5c5c !important; /* Rot für nicht akzeptiert */
}
.forwarding-status i {
  font-size: 1.5em; /* Größe des Icons */
  color: inherit; /* Vererbt die Farbe basierend auf akzeptiert/nicht akzeptiert */
}

.forwarding-status span {
  font-size: 1em; /* Textgröße */
  color: #e0e0e0; /* Standardfarbe für den Text */
}



.submission-date {
  display: block;
  color: #b0b0b0;
  font-size: 0.9em;
  margin-top: 5px;
}

.request-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.info-item {
  flex-basis: 100%;
  background-color: #2a2a2e;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

.info-item label {
  display: block;
  color: #66ff66;
  font-weight: 600;
  font-size: 0.95em;
  margin-bottom: 5px;
}

.info-item p {
  color: #e0e0e0;
  font-size: 1em;
  margin: 0;
}

.description-section {
  background-color: #2a2a2e;
  padding: 15px;
  border-radius: 8px;
}

.description-section label {
  display: block;
  color: #66ff66;
  font-weight: 600;
  font-size: 0.95em;
  margin-bottom: 5px;
}

.description-text {
  color: #e0e0e0;
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
  word-break: break-word;
}

.confirmation-warning {
  display: flex;
  align-items: center;
  background-color: #333333;
  color: #ffdd57;
  padding: 20px;
  border-radius: 8px;
  margin: 0; /* Entferne alle äußeren Abstände */
  text-align: left;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Kontrolliere den vertikalen Abstand zwischen Buttons */
  margin: 0; /* Entferne alle äußeren Abstände */
  align-items: center;
  padding-top: 10px; /* Optional: minimaler Abstand nach oben */
}

.confirmation-warning i {
  font-size: 1.5em;
  margin-right: 10px;
}

.confirmation-warning p {
  margin: 0;
  font-size: 1.05em;
  line-height: 1.6;
}

.confirmation-warning a {
  color: #ffdd57;
  text-decoration: underline;
}

.action-button {
  width: 100%;
  max-width: 300px;
  padding: 12px;
  font-size: 1em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.green-button {
  background-color: #4ade80;
  color: #1c1c1e;
}

.green-button:hover {
  background-color: #3cc96e;
}

.gray-button {
  background-color: #555555;
  color: #ffffff;
}

.gray-button:hover {
  background-color: #777777;
}

.error-message {
  color: #ff5c5c;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
}

/* Media Query für kleinere Bildschirme */
@media (max-width: 600px) {
  .request-header h1 {
    font-size: 1.5em;
  }

  .description-section {
    font-size: 0.9em;
    padding: 12px;
  }

  .confirmation-warning {
    padding: 15px;
    font-size: 0.9em;
  }

  .action-button {
    padding: 10px;
    font-size: 0.9em;
  }
}

</style>
