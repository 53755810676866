<template>
  <div v-if="username && email && spotId" class="submitted-request">
    <div class="request-header">
      <h1>Kontaktherstellung Bestätigen</h1>
      <span class="submission-date">{{ currentTimestamp }}</span>
    </div>

    <div class="request-content">
      <div class="info-section">
        <div class="info-item">
          <label>Benutzername</label>
          <p>{{ username }}</p>
        </div>
        <div class="info-item">
          <label>E-Mail-Adresse</label>
          <p>{{ email }}</p>
        </div>
        <div class="info-item">
          <label>Spot-ID</label>
          <p>{{ spotId }}</p>
        </div>
      </div>

      <!-- Hervorgehobene Informationsnachricht -->
      <div class="confirmation-warning">
        <i class="fas fa-exclamation-circle"></i>
        <p>
          Bitte bestätige diese Anfrage per E-Mail. Nach der Bestätigung wird die Anfrage verarbeitet und der Benutzername an den Ersteller des Spots weitergeleitet. Eine Rücknahme ist nicht möglich!
        </p>
      </div>

      <!-- Button-Gruppe für Aktionen -->
      <div class="actions">
        <router-link to="/spot-request">
          <button class="action-button green-button">Neue Anfrage erstellen</button>
        </router-link>
        <router-link to="/start">
          <button class="action-button gray-button">Zurück</button>
        </router-link>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'SubmittedAnswerRequestPage',
  data() {
    return {
      username: '',
      email: '',
      spotId: '',
      currentTimestamp: this.formatTimestamp(new Date())
    };
  },
  created() {
    this.username = localStorage.getItem('username') || '';
    this.email = localStorage.getItem('email') || '';
    this.spotId = localStorage.getItem('spotId') || '';

    if (!this.username || !this.email || !this.spotId) {
      this.$router.push('/anfrage-rueckmeldung'); // Zurück zur Eingabeseite leiten, wenn Daten fehlen
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('email');
      localStorage.removeItem('spotId');
      localStorage.removeItem('submissionSuccess');
    }
  },
  methods: {
    formatTimestamp(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    }
  }
};
</script>

<style scoped>
.submitted-request {
  max-width: 500px;
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  color: #f0f0f0;
  font-family: Arial, sans-serif;
}

.request-header {
  text-align: center;
  border-bottom: 1px solid #333;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.request-header h1 {
  font-size: 1.8em;
  color: #4ade80;
  margin: 0;
}

.submission-date {
  display: block;
  color: #b0b0b0;
  font-size: 0.9em;
  margin-top: 5px;
}

.request-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.info-item {
  flex-basis: 100%;
  background-color: #2a2a2e;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

.info-item label {
  display: block;
  color: #66ff66;
  font-weight: 600;
  font-size: 0.95em;
  margin-bottom: 5px;
}

.info-item p {
  color: #e0e0e0;
  font-size: 1em;
  margin: 0;
}

.description-section {
  background-color: #2a2a2e;
  padding: 15px;
  border-radius: 8px;
}

.description-section label {
  display: block;
  color: #66ff66;
  font-weight: 600;
  font-size: 0.95em;
  margin-bottom: 5px;
}

.description-text {
  color: #e0e0e0;
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
  word-break: break-word;
}

.confirmation-warning {
  display: flex;
  align-items: center;
  background-color: #333333;
  color: #ffdd57;
  padding: 20px;
  border-radius: 8px;
  margin: 0;
  text-align: left;
}

.confirmation-warning i {
  font-size: 1.5em;
  margin-right: 10px;
}

.confirmation-warning p {
  margin: 0;
  font-size: 1.05em;
  line-height: 1.6;
}

.confirmation-warning a {
  color: #ffdd57;
  text-decoration: underline;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding-top: 10px;
}

.action-button {
  width: 100%;
  max-width: 300px;
  padding: 12px;
  font-size: 1em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.green-button {
  background-color: #4ade80;
  color: #1c1c1e;
}

.green-button:hover {
  background-color: #3cc96e;
}

.gray-button {
  background-color: #555555;
  color: #ffffff;
}

.gray-button:hover {
  background-color: #777777;
}

.error-message {
  color: #ff5c5c;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
}

/* Media Query für kleinere Bildschirme */
@media (max-width: 600px) {
  .request-header h1 {
    font-size: 1.5em;
  }

  .description-section {
    font-size: 0.9em;
    padding: 12px;
  }

  .confirmation-warning {
    padding: 15px;
    font-size: 0.9em;
  }

  .action-button {
    padding: 10px;
    font-size: 0.9em;
  }
}

</style>
