<template>
    <div class="not-found-page">
      <h1>Seite nicht gefunden</h1>
      <p>Die von dir angeforderte Seite existiert nicht oder wurde verschoben.</p>
      <router-link to="/">
        <button class="back-button">spottedHSK.de</button>
      </router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFoundPage'
  };
  </script>
  
  <style scoped>
.not-found-page {
  display: flex;
  flex-direction: column; /* Inhalte vertikal anordnen */
  align-items: center; /* Zentriert Inhalte horizontal */
  justify-content: center; /* Zentriert Inhalte vertikal */
  max-width: 500px;
  margin: 0 auto;
  height: 100vh; /* Nutze die volle Höhe des Viewports */
  padding: 30px;
  text-align: center;
  color: #f0f0f0;
  animation: fadeIn 1s ease-in-out forwards;
}

h1 {
  font-size: 2em;
  font-weight: bold;
  color: #4ade80;
  margin-bottom: 20px;
}

p {
  font-size: 1.1em;
  color: #a0a0a0;
  margin-bottom: 30px;
}

a {
  text-decoration: none; /* Entfernt Unterstreichung */
}

.back-button {
  padding: 15px 30px; /* Breite und Höhe des Buttons */
  font-size: 1em;
  background-color: #66ff66;
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-decoration: none; /* Sicherstellt, dass keine Unterstreichung vorhanden ist */
}

.back-button:hover {
  background-color: #777777;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

  </style>
  