<template>
  <div class="feedback-page">
    <button @click="goBack" class="back-button">
        <i class="fas fa-arrow-left"></i> Zurück
      </button>
    <h1>Kontaktherstellung</h1>

    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <div class="input-icon" :class="{ 'error-border': isSubmitted && errors.email }">
          <i class="fas fa-envelope"></i>
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder="E-Mail-Adresse"
          />
        </div>
      </div>

      <div class="form-group">
        <div class="username-container input-icon" :class="{ 'error-border': isSubmitted && errors.username }">
          <span class="at-symbol">@</span>
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="Benutzername"
          />
        </div>
      </div>

      <div class="form-group">
        <div class="input-icon" :class="{ 'error-border': isSubmitted && errors.spotId }">
          <i class="fas fa-hashtag"></i>
          <input
            type="text"
            v-model="spotId"
            placeholder="Spot-ID"
            @input="handleSpotIdInput"
          />
          <span v-if="spotIdStatus === 'valid'" class="status-icon valid fas fa-circle-check"></span>
          <span v-if="spotIdStatus === 'invalid'" class="status-icon invalid fas fa-circle-xmark"></span>
        </div>

        <p v-if="spotIdStatus === 'invalid'" class="spot-id-error">
          Diese Spot-ID existiert nicht oder der Ersteller des Spots hat die Weiterleitung seinen Benutzernamens nicht zugestimmt.
        </p>
      </div>


      <div class="switch-group">
        <div class="switch-item" :class="{ 'error-border': isSubmitted && errors.allowContact }">
          <div class="label-container">
            <label>Kontaktaufnahme per E-Mail erlauben</label>
          </div>
          <label class="switch">
            <input type="checkbox" v-model="allowContact" />
            <span class="slider"></span>
          </label>
        </div>

        <div class="switch-item" :class="{ 'error-border': isSubmitted && errors.privacyConsent }">
          <div class="label-container">
            <label>
              Ich stimme der Verarbeitung meiner Daten gemäß der 
              <a href="/datenschutz" target="_blank">Datenschutzerklärung</a> zu.
            </label>
          </div>
          <label class="switch">
            <input type="checkbox" v-model="privacyConsent" />
            <span class="slider"></span>
          </label>
        </div>
      </div>


      <div v-if="Object.keys(errors).length > 0 && isSubmitted" class="error-messages">
        <ul>
          <li v-for="(message, key) in errors" :key="key" class="error-text">{{ message }}</li>
        </ul>
      </div>


      <button 
        type="submit" 
        class="submit-button" 
        :disabled="!isFormValid() || spotIdStatus !== 'valid'"
      >Absenden</button>

      <p class="info-text">
        Nach dem Absenden wird Ihr Benutzername per E-Mail an den Ersteller des Spots weitergeleitet, falls die Spot-ID gültig ist und der Ersteller dies erlaubt hat.
      </p>
    </form>

  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'AnswerRequestPage',
  data() {
    return {
      email: '',
      username: '',
      spotId: '',
      allowContact: false,
      privacyConsent: false,
      spotIdStatus: null,
      errors: {},
      isSubmitted: false,
    };
  },
watch: {
    // Beobachte Änderungen an der Route
    "$route.params.spotId": {
      immediate: true, // Wird direkt bei Komponente-Mount ausgeführt
      handler(newSpotId) {
        this.spotId = newSpotId || ""; // Setze Spot-ID, wenn vorhanden
        if (this.spotId) {
          this.checkSpotIdExists(); // Überprüfe Spot-ID, wenn sie gesetzt ist
        }
      },
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Eine Seite zurück
    },
    handleSpotIdInput(event) {
      this.validateSpotId(event); // Validiert die Eingabe
      this.checkSpotIdExists(); // Überprüft, ob die Spot-ID existiert
    },
    async checkSpotIdExists() {
      if (!this.spotId) {
        this.spotIdStatus = null;
        return;
      }
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/check-spot-id`, { spotId: this.spotId });
        this.spotIdStatus = response.data.exists ? 'valid' : 'invalid';
      } catch (error) {
        this.spotIdStatus = 'invalid';
      }
    },
    validateSpotId() {
      this.spotId = this.spotId.replace(/\D/g, '');
      this.spotIdStatus = null;
    },
    validateForm() {
      this.errors = {};

      if (!this.email || !this.validateEmail(this.email)) {
        this.errors.email = 'Bitte eine gültige E-Mail-Adresse eingeben.';
      }
      if (this.username.length < 3 || this.username.length > 30) {
        this.errors.username = 'Der Benutzername muss zwischen 3 und 30 Zeichen lang sein.';
      }
      if (!this.spotId) {
        this.errors.spotId = 'Bitte eine Spot-ID eingeben.';
      }
      if (!this.allowContact) {
        this.errors.allowContact = 'Bitte erlauben Sie die Kontaktaufnahme per E-Mail.';
      }
      if (!this.privacyConsent) {
        this.errors.privacyConsent = 'Bitte stimmen Sie der Datenschutzerklärung zu.';
      }

      return Object.keys(this.errors).length === 0;
    },
    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    isFormValid() {
      return (
        this.email &&
        this.validateEmail(this.email) &&
        this.username.length >= 3 &&
        this.username.length <= 30 &&
        this.allowContact &&
        this.privacyConsent
      );
    },
    async handleSubmit() {
    this.isSubmitted = true;
    if (this.validateForm() && this.spotIdStatus === 'valid') {
      try {
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/create-request`, {
          email: this.email,
          username: this.username,
          spot_id: this.spotId,
          allowContact: this.allowContact,
          privacyConsent: this.privacyConsent,
          allowUsername: 1,
          type: 'RAW_INPUT_RESPONSE'
        });


        // Setze submissionSuccess im localStorage
        localStorage.setItem('submissionSuccess', 'true');
        localStorage.setItem('username', this.username);
        localStorage.setItem('email', this.email);
        localStorage.setItem('spotId', this.spotId);

        this.$router.push({ name: 'SubmittedAnswerRequestPage' });
        this.resetForm();
      } catch (error) {
        // Fehlerbehandlung
        this.errors.backend = error.response?.data?.message || 'Fehler beim Senden der Anfrage';
      }
    }
  },
    resetForm() {
      this.email = '';
      this.username = '';
      this.spotId = '';
      this.allowContact = false;
      this.privacyConsent = false;
      this.spotIdStatus = null;
      this.errors = {};
      this.isSubmitted = false;
    }
  }
};
</script>



<style scoped>
.feedback-page {
  max-width: 500px;
  margin: 40px auto;
  padding: 30px;
  color: #f0f0f0;
  margin: -60px auto 0; 
}

.feedback-page {
  padding-top: 0;
}

h1 {
  text-align: center;
  font-size: 2em;
  color: #4ade80;
  margin-top: 0;
}

.form-group {
  margin-bottom: 20px;
}

.input-icon {
  position: relative;
}

.input-icon i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #a0a0a0;
}

input[type="text"],
input[type="email"] {
  padding: 10px 10px 10px 35px;
  font-size: 1em;
  background-color: #2c2c2e;
  color: #f0f0f0;
  border-radius: 8px;
  border: 1px solid #333;
  width: 100%;
  padding-right: 50px;
}

.status-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em;
}

.valid {
  color: #4ade80;
}

.invalid {
  color: #ff5c5c;
}

.spot-id-error {
  color: #ff5c5c;
  font-size: 0.85em;
  margin-top: 5px;
  text-align: left;
}

.error-border {
  border: 1px solid red !important;
}

.error-messages {
  margin-top: 20px;
  padding: 10px;
  background-color: #ffdddd;
  border: 1px solid #ff5c5c;
  border-radius: 8px;
  color: #b30000;
}

.error-text {
  font-size: 0.9em;
}

.info-text {
  font-size: 0.85em;
  color: #a0a0a0;
  text-align: center;
  margin-top: 20px;
  line-height: 1.4;
}

.submit-button {
  width: 100%;
  padding: 15px;
  font-size: 1.1em;
  background-color: #4ade80;
  color: #1c1c1e;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:disabled {
  background-color: #888888;
  cursor: not-allowed;
}

.submit-button:hover:enabled {
  background-color: #3cc96e;
}

.back-button {
    background-color: transparent;
    border: none;
    color: #4ade80;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    padding: 5px;
    font-weight: bold;
  }
  
  .back-button i {
    font-size: 1.2em;
  }
</style>
