<template>
    <div class="spots-overview-page">
      <!-- Zurück Button -->
      <button @click="goBack" class="back-button">
        <i class="fas fa-arrow-left"></i> Zurück
      </button>
  
      <h1>Übersicht der Spots</h1>
  
      <!-- Filter-Bereich -->
      <div class="filter-container">
  <input
    type="text"
    v-model="searchQuery"
    placeholder="Spot filtern..."
    class="filter-input"
  />
  <button @click="toggleFilter" class="filter-button">
  <i :class="filterStatus30 ? 'fas fa-list' : 'fas fa-envelope-open-text'"></i>
  {{ filterStatus30 ? "Alle Spots anzeigen" : "Rückmeldung ausstehend" }}
</button>
</div>
      <!-- Spots-Liste -->
      <div class="spots-list">
        <div
  v-for="spot in spots"
  :key="spot.spot_id"
  class="spot-card"
>
  <div class="spot-header">
    <span class="spot-date">{{ formatDate(spot.timestamp) }}</span>
    <span class="spot-id">Spot-ID {{ spot.spot_id }}</span>
  </div>
  <p class="spot-description">{{ spot.description }}</p>

  <!-- Weiterleitungsstatus unten rechts -->
  <div class="forwarding-status">
    <router-link
      v-if="spot.forwarding_allowed === 1 && spot.status === 30"
      :to="{ name: 'AnswerRequestPage', params: { spotId: spot.spot_id } }"
      :title="'Rückmeldung zur Spot-ID ' + spot.spot_id + ' erstellen'"
    >
      <span class="allowed">@</span>
    </router-link>
    <span
      v-else
      class="not-allowed"
      :title="'Benutzername darf nicht weitergeleitet werden'"
    >
      @
    </span>
  </div>
</div>

  
        <!-- loadMoreTrigger-Element -->
        <div ref="loadMoreTrigger" class="load-more-trigger" v-if="!allLoaded && !isSearching"></div>
      </div>
  
      <!-- Ladeanzeige -->
      <div v-if="loading" class="loading">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
  
      <!-- Meldung für keine Suchergebnisse -->
      <div v-if="!loading && spots.length === 0 && searchQuery" class="no-results">
        <p>Zu deiner Suche konnte nichts gefunden werden.</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "SpotsOverviewPage",
    data() {
      return {
        searchQuery: "",
        spots: [],
        loading: false,
        limit: 20,
        offset: 0,
        allLoaded: false,
        isSearching: false,
        filterStatus30: false, // Zustand für die "Nicht erledigt"-Filterung
      };
    },
    watch: {
      async searchQuery() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(async () => {
          this.isSearching = this.searchQuery.trim().length > 0;
          this.offset = 0;
          this.spots = [];
          this.allLoaded = false;
          await this.loadSpots();
        }, 300);
      },
    },
    methods: {
      formatDate(dateString) {
        const date = new Date(dateString);
        return (
          date.toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }) + " Uhr"
        );
      },
      async loadSpots() {
  if (this.loading || this.allLoaded) return;

  this.loading = true;
  try {
    const url = `${process.env.VUE_APP_API_BASE_URL}/api/spots`;
    const params = { limit: this.limit, offset: this.offset };

    // Prüfen, ob die Suchanfrage eine Spot ID ist (nur Zahlen)
    if (this.searchQuery && /^\d+$/.test(this.searchQuery)) {
      params.spot_id = this.searchQuery.trim();
    }
    // Prüfen, ob die Suchanfrage ein Text ist
    else if (this.searchQuery) {
      params.search = this.searchQuery.trim();
    }

    // Filter für Status 30
    if (this.filterStatus30) {
      params.status = 30;
      params.forwarding_allowed = 1;
    }

    console.log("Request Params:", params); // Debugging

    const response = await axios.get(url, { params });

    // Ergebnisse verarbeiten
    let fetchedSpots = response.data;

    if (this.filterStatus30) {
      fetchedSpots = fetchedSpots.filter(
        (spot) => spot.status === 30 && spot.forwarding_allowed === 1
      );
    }

    if (this.searchQuery || this.filterStatus30) {
      this.spots = fetchedSpots;
      this.allLoaded = true;
    } else {
      if (fetchedSpots.length > 0) {
        this.spots.push(...fetchedSpots);
        this.offset += this.limit;
      } else {
        this.allLoaded = true;
      }
    }
  } catch (error) {
    console.error("Fehler beim Laden der Spots:", error);
  } finally {
    this.loading = false;
  }
}
,

      filterUnresolvedSpots() {
        this.filterStatus30 = true;
        this.offset = 0;
        this.spots = [];
        this.allLoaded = false;
        this.loadSpots();
      },
      toggleFilter() {
    this.filterStatus30 = !this.filterStatus30; // Filterstatus umschalten
    this.offset = 0; // Offset zurücksetzen
    this.spots = []; // Liste der Spots leeren
    this.allLoaded = false; // "Alle geladen"-Flag zurücksetzen
    this.loadSpots(); // Spots neu laden
  },
      setupIntersectionObserver() {
        if (this.observer) this.observer.disconnect();
  
        this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                entry.isIntersecting &&
                !this.loading &&
                !this.allLoaded &&
                !this.isSearching &&
                !this.filterStatus30
              ) {
                this.loadSpots();
              }
            });
          },
          {
            root: null,
            rootMargin: "0px",
            threshold: 1.0,
          }
        );
  
        if (this.$refs.loadMoreTrigger) {
          this.observer.observe(this.$refs.loadMoreTrigger);
        }
      },
      goBack() {
        this.$router.go(-1);
      },
    },
    created() {
      this.loadSpots();
    },
    mounted() {
      this.setupIntersectionObserver();
    },
    beforeUnmount() {
      if (this.observer) {
        this.observer.disconnect();
      }
    },
  };
  </script>
  
  <style scoped>

  .spots-overview-page {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #d4d4d4;
    text-align: left;
    min-height: 80vh;
    width: 100%;
  }
  
  .spot-card {
    background-color: #1f1f1f;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .forwarding-status {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    padding-top: 10px; /* Abstand zum oberen Text */
  }
  
  .allowed {
    color: #4ade80; /* Grün für erlaubt */
  }
  
  .not-allowed {
    color: #ff6b6b; /* Rot für nicht erlaubt */
    text-decoration: line-through;
  }
  
  h1 {
    text-align: center;
    color: #4ade80;
  }
  
  .back-button {
    background-color: transparent;
    border: none;
    color: #4ade80;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    padding: 5px;
    font-weight: bold;
  }
  
  .back-button i {
    font-size: 1.2em;
  }
  
  .no-results {
    text-align: center;
    color: #ff6b6b;
    font-size: 1.2em;
    margin-top: 20px;
  }

  .filter-button i {
  margin-right: 10px; /* Abstand zwischen Icon und Text */
  font-size: 16px; /* Größe des Icons */
  vertical-align: middle; /* Vertikale Ausrichtung */
}
  
.forwarding-status .allowed {
  color: #4ade80; /* Grün */
  cursor: pointer;
  text-decoration: none;
}

  
  
  .filter-container {
  display: flex;
  flex-direction: column; /* Eingabefeld und Button vertikal anordnen */
  align-items: stretch; /* Elemente auf gleiche Breite ausrichten */
  gap: 15px; /* Abstand zwischen Eingabefeld und Button */
  max-width: 400px; /* Optionale Begrenzung der Breite */
  margin: 0 auto; /* Zentriert den gesamten Filterbereich */
  padding: 20px; /* Platz um die Filter-Box */

}

.filter-input {
  width: 100%; /* Eingabefeld nimmt die gesamte Breite ein */
  padding: 12px 15px; /* Angenehmer Abstand innen */
  font-size: 16px; /* Gut lesbare Schriftgröße */
  border: 1px solid #ccc; /* Dezente Umrandung */
  border-radius: 4px; /* Abgerundete Ecken */
  transition: border-color 0.3s; /* Sanfter Effekt beim Fokus */
}

.filter-input:focus {
  border-color: #4ade80; /* Farbänderung beim Fokus */
  outline: none; /* Standard-Fokus entfernen */
}

.filter-button {
  display: flex; /* Flexbox verwenden */
  align-items: center; /* Vertikale Zentrierung von Icon und Text */
  justify-content: center; /* Horizontale Zentrierung, falls nötig */
  gap: 8px; /* Abstand zwischen Icon und Text */
  width: 100%; /* Optional: Breite des Buttons */
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  background-color: #4ade80; /* Hintergrundfarbe */
  color: #1f1f1f; /* Schriftfarbe */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-button i {
  font-size: 18px; /* Größe des Icons */
  line-height: 1; /* Verhindert zusätzliche Höhe */
}

.filter-button span {
  line-height: 1; /* Gleiche Linie für Text */
}

.filter-button:hover {
  background-color: #3bc469; /* Hover-Farbe */
}
  .spots-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .spot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  
  .spot-date {
    color: #4ade80;
    font-size: 0.9em;
    line-height: 1;
  }
  
  .spot-id {
    color: #a0a0a0;
    font-size: 0.9em;
    text-align: right;
    padding: 4px 8px;
    border-radius: 4px;
    line-height: 1;
  }
  
  .spot-description {
    margin-top: 10px;
    margin-bottom: 15px;
    color: #d4d4d4;
    text-align: left;
    line-height: 1.6;
  }
  
  .loading {
    text-align: center;
    padding: 10px;
    color: #4ade80;
    font-size: 1.5em;
  }
  
  .load-more-trigger {
    height: 1px;
  }
  </style>
  