<template>
  <div id="app" class="landing-page">
    <router-view />
    <AppFooter v-if="$route.path === '/'" />
  </div>
</template>

<script>
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppFooter,
  },
};
</script>


<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: 'Arial', sans-serif;
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.landing-page {
  flex: 1; /* Nimmt den verfügbaren Platz ein */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #121212; /* Tiefes Dunkelgrau für den Hintergrund */
  color: #ffffff;
  text-align: center;
}

.center-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5em;
  font-weight: 700;
  background: linear-gradient(135deg, #66ff66, #00cc66); /* Grün-Farbverlauf */
  background-clip: text;
  color: transparent;
  margin-bottom: 10px;
}

.tagline {
  font-size: 1.3em;
  margin-bottom: 20px;
  color: #b3b3b3;
}

.cta-button {
  padding: 10px 20px;
  font-size: 1.2em;
  background-color: #07a455; 
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #00994d; 
}

/* Footer Styling */
footer {
  width: 100%;
  padding: 15px;
  background-color: #1e1e1e; 
  color: #b3b3b3;
  text-align: center;
  font-size: 0.9em;
  border-top: 1px solid #333;
}

footer a {
  color: #66ff66;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #00cc66;
}

/* Animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
