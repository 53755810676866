<template>
  <div class="home">
    <div class="center-content">
      <!-- Logo oben -->
      <a href="https://spottedhsk.de" target="_self">
        <img src="https://spottedhsk.de/images/logo.png" alt="spottedHSK Logo" class="logo">
      </a>

      <!-- Tagline -->
      <p class="tagline">Teile deine Beobachtungen anonym und einfach</p>

      <!-- Statistiken -->
      <div class="stats-container">
        <div class="stat-item">
          <i class="fas fa-eye stat-icon"></i>
          <div class="stat-label">Spots</div>
          <div class="count">{{ animatedStats.spots }}</div>
        </div>
        <div class="stat-item">
          <i class="fas fa-comments stat-icon"></i>
          <div class="stat-label">Verbindungen</div>
          <div class="count">{{ animatedStats.responses }}</div>
        </div>
      </div>

      <!-- Button -->
      <router-link to="/start">
        <button class="cta-button">
          <i class="fas fa-arrow-right"></i>
        </button>
      </router-link>
    </div>
    <AppFooter />
  </div>
</template>


<script>
import AppFooter from './AppFooter.vue';
import axios from 'axios';

export default {
  name: 'HomePage',
  components: {
    AppFooter,
  },
  metaInfo: {
    title: 'spottedHSK - Teile deine Beobachtungen anonym',
    meta: [
      { name: 'description', content: 'Teile anonym Beobachtungen in deiner Umgebung mit spottedHSK.' },
      { name: 'keywords', content: 'spottedHSK, anonym, Beobachtungen, Verbindungen, Hochsauerlandkreis, HSK, spotted, spotted HSK, Arnsberg, Neheim, Sundern, Meschede, Brilon, spottedHSK.de' },
    ]
  },
  data() {
    return {
      stats: {
        spots: 0,
        responses: 0,
        errorMessage: ""  // Neue Variable für Fehlernachrichten
      },
      animatedStats: {
        spots: 0,
        responses: 0,
        errorMessage: ""  // Neue Variable für Fehlernachrichten
      },
    };
  },
  created() {
    // Lade vorherige Statistiken aus localStorage (falls vorhanden)
    const savedStats = JSON.parse(localStorage.getItem('stats'));
    if (savedStats) {
      this.stats = savedStats;
      this.animatedStats.spots = savedStats.spots;
      this.animatedStats.responses = savedStats.responses;
    }

    // Versuche, neue Statistiken zu laden
    this.fetchStats();
  },
  methods: {
    async fetchStats() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/stats`);
    this.stats = response.data;
    localStorage.setItem('stats', JSON.stringify(this.stats));
    this.animateCount('spots', this.stats.spots);
    this.animateCount('responses', this.stats.responses);
  } catch (error) {
    this.errorMessage = error.response?.data?.error || "Die Statistiken sind derzeit nicht verfügbar.";
  }
},
    animateCount(statName, targetValue) {
      const increment = targetValue / 100; // Steigert die Geschwindigkeit
      let current = 0;
      const interval = setInterval(() => {
        current += increment;
        if (current >= targetValue) {
          this.animatedStats[statName] = targetValue;
          clearInterval(interval);
        } else {
          this.animatedStats[statName] = Math.floor(current);
        }
      }, 15); // Intervallzeit für Geschwindigkeitsanpassung
    },
  },
};
</script>



<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.home {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertikale Zentrierung */
  align-items: center; /* Horizontale Zentrierung */
  min-height: 100vh; /* Vollständige Höhe des Viewports */
  text-align: center;
  padding: 0 20px;
  background-color: #121212; /* Hintergrundfarbe */
}


.center-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontale Zentrierung */
  justify-content: center; /* Vertikale Zentrierung */
  padding: 20px;
  max-width: 100%;
}

.logo {
  width: 550px;
  height: auto;
  margin: 0 auto; /* Entfernt ungewollte Abstände */
  display: block; /* Stellt sicher, dass es wie ein Block behandelt wird */
}
.tagline {
  font-size: 1.3em;
  margin: 0;
  padding: 10px 0; /* Exakter Abstand zum Logo */
  line-height: 1.5; /* Kontrolliert den vertikalen Abstand */
  color: #b3b3b3;
  text-align: center; /* Sicherstellt, dass der Text zentriert ist */
}


.stats-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 20px 0;
}


@media (max-width: 768px) {
  .logo {
    width: 300px; /* Reduzierte Breite für Tablets */
  }
}

@media (max-width: 480px) {
  .logo {
    width: 350px; /* Reduzierte Breite für Smartphones */
  }
}

.stat-item {
  flex: 1;
  min-width: 120px;
  max-width: 150px;
  padding: 20px;
  background-color: #222222;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  transition: transform 0.3s ease;
}

.stat-icon {
  font-size: 1.8em;
  color: #66ff66;
  margin-bottom: 10px;
}

.stat-label {
  font-size: 0.9em;
  color: #b3b3b3;
  margin-bottom: 5px;
}

.count {
  font-size: 1.5em;
  font-weight: bold;
  color: #ffffff;
}

.cta-button {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  font-size: 1.2em;
  background-color: #66ff66;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 0 25px;
  text-decoration: none; /* Entfernt die Unterstreichung */
}

/* Spezifische Regeln für router-link */
.router-link-active .cta-button,
.router-link-exact-active .cta-button {
  text-decoration: none;
}


.cta-button:active {
  transform: translateY(1px);
}

.cta-button i {
  font-size: 1.2em;
  color: #1f1f1f;
  text-decoration: none; /* Entfernt auch für Icons die Unterstreichung */
}

/* Entfernen Sie Unterstreichung für alle Links */
a, a:visited, a:hover, a:active {
  text-decoration: none;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>