<template>
  <footer class="footer">
    <div class="footer-content">
      <p>&copy; 2024 spottedHSK | <a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutz</a></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
.footer {
  width: 100%;
  padding: 15px;
  background-color: #1b1b1b;
  color: #b3b3b3;
  text-align: center;
  font-size: 0.85em;
  border-top: 1px solid #333;
  position: absolute;
  bottom: 0;
  left: 0;
}
.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.footer a {
  color: #66ff66;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #00cc66;
}
</style>
