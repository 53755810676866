<template>
  <div class="search-user-page">
    <button @click="goBack" class="back-button">
  <i class="fas fa-arrow-left"></i> <span>Zurück</span>
</button>
    <h1>{{ pageTitle }}</h1>
    <form @submit.prevent="handleSubmit">
      <!-- Email Input -->
      <div class="form-group">
        <div class="input-icon" :class="{ 'error-border': errors.email }">
          <i class="fas fa-envelope"></i>
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder="E-Mail-Adresse"
          />
        </div>
      </div>

      <!-- Username Input -->
      <div class="form-group">
        <div class="username-container input-icon" :class="{ 'error-border': errors.username && (username.length < 3 || username.length > 30) }">
          <span class="at-symbol">@</span>
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="Benutzername"
            @input="validateUsername"
            @focus="setCursorPosition"
          />
        </div>
      </div>

      <!-- Description Input -->
      <div class="form-group">
        <div class="input-icon textarea-icon">
          <i class="fas fa-align-left"></i>
          <textarea
            id="description"
            v-model="description"
            placeholder="Beschreibe, wonach du suchst"
            rows="6"
            maxlength="512"
            :class="{ 'error-border': isSubmitted && description.length < 16 }"
          ></textarea>
        </div>
        <!-- Character Count Message -->
        <span class="char-count" :class="{ 'error-text': isSubmitted && description.length < 16 }">
          {{ description.length < 16 ? `Noch mindestens ${16 - description.length} Zeichen nötig` : `${512 - description.length} Zeichen übrig` }}
        </span>
      </div>

      <!-- Switches -->
      <div class="switch-group">
        <div class="switch-item" :class="{ 'error-border': errors.allowContact }">
          <div class="label-container">
            <label>Kontaktaufnahme per E-Mail erlauben</label>
          </div>
          <label class="switch">
            <input type="checkbox" v-model="allowContact" />
            <span class="slider"></span>
          </label>
        </div>

        <div class="switch-item">
          <div class="label-container">
            <label>Weiterleitung des Benutzernamens erlauben</label>
          </div>
          <label class="switch">
            <input type="checkbox" v-model="allowUsername" />
            <span class="slider"></span>
          </label>
        </div>

        <div class="switch-item" :class="{ 'error-border': errors.privacyConsent }">
          <div class="label-container">
            <label>
              Ich stimme der Verarbeitung meiner Daten gemäß der 
              <a href="/datenschutz" target="_blank">Datenschutzerklärung</a> zu.
            </label>
          </div>
          <label class="switch">
            <input type="checkbox" v-model="privacyConsent" />
            <span class="slider"></span>
          </label>
        </div>
      </div>

      <!-- Error Messages -->
      <div v-if="Object.keys(errors).length > 0" class="error-messages">
        <ul>
          <li v-for="(message, key) in errors" :key="key" class="error-text">{{ message }}</li>
        </ul>
      </div>

      <!-- Submit Button -->
      <button type="submit" class="submit-button" :disabled="!isFormValid">Absenden</button>
    </form>
    
    <p class="info-message">
      Die IP-Adresse jeder Anfrage wird zur Absicherung von spottedHSK.de und zum Schutz vor Missbrauch gespeichert. Diese Daten werden nur im Bedarfsfall und gemäß den gesetzlichen Vorgaben genutzt.
    </p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CreateRequestPage',
  data() {
    return {
      pageTitle: 'Anfrage erstellen', // Dynamischer Titel
      email: '',
      username: '',
      description: '',
      allowUsername: false,
      allowContact: false,
      privacyConsent: false,
      errors: {},
      isSubmitted: false,
      requestData: JSON.parse(localStorage.getItem('submittedRequest')) || null
    };
  },
  computed: {
    isFormValid() {
      return (
        this.email && 
        this.validateEmail(this.email) &&
        this.username.length >= 3 &&
        this.username.length <= 30 &&
        this.description.length >= 16 &&
        this.privacyConsent &&
        this.allowContact
      );
    }
  },
  methods: {
    goBack() {
  this.$router.go(-1); // Eine Seite zurück
},
    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    validateUsername() {
      this.username = this.username.replace(/[^a-zA-Z0-9._]/g, '');
    },
    setCursorPosition(event) {
      const input = event.target;
      input.setSelectionRange(1, 1);
    },
    validateForm() {
      this.errors = {};

      if (!this.email || !this.validateEmail(this.email)) {
        this.errors.email = 'Bitte eine gültige E-Mail-Adresse eingeben.';
      }
      if (this.username.length < 3 || this.username.length > 30) {
        this.errors.username = 'Der Benutzername muss zwischen 3 und 30 Zeichen lang sein.';
      }
      if (this.description.length < 16 || this.description.length > 512) {
        this.errors.description = 'Die Beschreibung muss zwischen 16 und 512 Zeichen lang sein.';
      }
      if (!this.allowContact) {
        this.errors.allowContact = 'Bitte erlauben Sie die Kontaktaufnahme per E-Mail.';
      }
      if (!this.privacyConsent) {
        this.errors.privacyConsent = 'Bitte stimmen Sie der Datenschutzerklärung zu.';
      }

      return Object.keys(this.errors).length === 0;
    },
    async handleSubmit() {
  this.isSubmitted = true;
  this.errors = {}; // Fehler zurücksetzen bei jedem Submit

  if (this.validateForm()) {
    try {

      await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/create-request`, {
        email: this.email,
        username: this.username,
        description: this.description,
        allowUsername: this.allowUsername,
        type: 'RAW_INPUT_POST',
      });

      // Speichern der Anfrage im localStorage
      localStorage.setItem('submittedRequest', JSON.stringify({
        email: this.email,
        username: this.username,
        allowUsername: this.allowUsername,
        description: this.description,
        timestamp: new Date().toISOString(),
      }));

      // Weiterleitung nach erfolgreicher Anfrage
      this.$router.push({ name: 'SubmittedRequestPage' });
    } catch (error) {
      if (error.response) {
        this.errors.backend = typeof error.response.data === 'string'
          ? error.response.data
          : error.response.data.message || 'Ein Fehler ist aufgetreten.';
      } else if (error.request) {
        this.errors.backend = 'Keine Antwort vom Server.';
      } else {
        this.errors.backend = 'Ein unerwarteter Fehler ist aufgetreten.';
      }
    }
  }
}

  },
  watch: {
    email(newValue) {
      if (newValue && this.validateEmail(newValue)) {
        delete this.errors.email;
      }
    },
    username(newValue) {
      if (newValue.length >= 3 && newValue.length <= 30) {
        delete this.errors.username;
      }
    },
    description(newValue) {
      if (newValue.length >= 16 && newValue.length <= 512) {
        delete this.errors.description;
      }
    },
    allowContact(newValue) {
      if (newValue) {
        delete this.errors.allowContact;
      }
    },
    privacyConsent(newValue) {
      if (newValue) {
        delete this.errors.privacyConsent;
      }
    }
  }
};
</script>




<style>
.search-user-page {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  background-color: transparent;
  overflow-y: auto; 
  color: #f0f0f0;
  margin: -60px auto 0; 
  animation: fadeIn 1s ease-in-out forwards; 
}
/* Animation Definition */
@keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

.submit-button:disabled {
      background-color: #888888;
      cursor: not-allowed;
      opacity: 0.6;
    }

    .submit-button:disabled:hover {
      background-color: #888888;
      cursor: not-allowed;
      opacity: 0.6;
    }

h1 {
text-align: center;
font-size: 2em;
font-weight: bold;
margin-bottom: 20px;
position: sticky; 
color: #4ade80;
}

.form-group {
margin-bottom: 20px;
display: flex;
flex-direction: column;
}

.input-icon {
position: relative;
}

.input-icon i {
position: absolute;
left: 10px;
top: 50%;
transform: translateY(-50%);
color: #a0a0a0;
}

.username-container {
position: relative;
border-radius: 8px;
}

.username-container .at-symbol {
position: absolute;
left: 10px;
top: 50%;
transform: translateY(-50%);
color: #a0a0a0;
font-size: 1em;
}

.username-container input {
padding-left: 30px;
font-size: 1em;
background-color: #2c2c2e;
color: #f0f0f0;
border-radius: 8px;
border: 1px solid #333;
width: 100%;
transition: border-color 0.3s;
}


/* Fehler-Border für alle Felder */
.error-border {
border: 1px solid red !important;
border-radius: 8px;
}

/* Grüner Fokus nur, wenn kein Fehler vorhanden */
input:not(.error-border):focus,
textarea:not(.error-border):focus {
outline: none;
border-color: #4ade80 !important; /* Grüner Fokus */
}

/* Fokus bei Fehlern bleibt rot */
input.error-border:focus,
textarea.error-border:focus {
border-color: red !important;
}

/* Einheitliche Stile für Eingabefelder */
input[type="text"],
input[type="email"],
textarea {
padding: 10px 10px 10px 35px;
font-size: 1em;
background-color: #2c2c2e;
color: #f0f0f0;
border-radius: 8px;
border: 1px solid #333;
width: 100%;
transition: border-color 0.3s;
}

.textarea-icon i {
top: 10px;
transform: none;
}

textarea {
resize: none;
height: 80px;
padding-top: 10px;
}

input:focus,
textarea:focus {
outline: none;
border-color: #4ade80;
}

.char-count {
font-size: 0.85em;
color: #a0a0a0;
text-align: right;
margin-top: 5px;
}

.switch-group {
display: flex;
flex-direction: column;
gap: 10px;
}

.switch-item {
display: flex;
align-items: center;
justify-content: space-between;
}

.switch-item .label-container {
flex: 1;
text-align: left;
}

.switch-item label {
font-size: 0.9em;
color: #a0a0a0;
}

.switch {
position: relative;
width: 42px;
height: 22px;
}

.switch input {
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #555;
transition: 0.4s;
border-radius: 22px;
}

.slider:before {
position: absolute;
content: "";
height: 18px;
width: 18px;
left: 2px;
bottom: 2px;
background-color: #fff;
transition: 0.4s;
border-radius: 50%;
}

input:checked + .slider {
background-color: #4ade80;
}

input:checked + .slider:before {
transform: translateX(20px);
}

.submit-button {
width: 100%;
padding: 15px;
font-size: 1.1em;
margin-top: 20px;
font-weight: bold;
background-color: #4ade80;
color: #1c1c1e;
border: none;
border-radius: 8px;
cursor: pointer;
transition: background-color 0.3s ease;
}

.submit-button:hover {
background-color: #3cc96e;
}

.submit-button:active {
transform: translateY(1px);
}

.error-messages {
margin-top: 20px;
padding: 10px;
background-color: #ffdddd;
border: 1px solid #ff5c5c;
border-radius: 8px;
color: #b30000;
}

.error-messages ul {
list-style-type: none;
padding: 0;
margin: 0;
}

.error-messages li {
margin-bottom: 5px;
font-size: 0.9em;
}

.error-border {
border: 1px solid red;
}

.error-text {
color: red;
}

@media (max-width: 600px) {
.search-user-page {
  padding: 20px;
}

h1 {
  font-size: 1.8em;
}

.submit-button {
  font-size: 1em;
  padding: 12px;
}
}

.switch-group .switch-item a {
color: #4ade80;
text-decoration: none;
}

.switch-group .switch-item a:hover {
text-decoration: underline;
}

.info-message {
font-size: 0.85em;
color: #b0b0b0;
text-align: center;
margin-top: 20px;
line-height: 1.4;
}


/* Beschreibung (textarea) Anpassungen */
.textarea-icon i {
top: 10px;
transform: none;
}

textarea {
resize: none;

height: 80px;
padding-top: 10px;
padding-left: 35px; /* Um das Icon oder Padding anzupassen */
background-color: #2c2c2e;
color: #f0f0f0;
border-radius: 8px;
border: 1px solid #333;
width: 100%;
transition: border-color 0.3s;
}

.back-button {
    background-color: transparent;
    border: none;
    color: #4ade80;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    padding: 5px;
    font-weight: bold;
  }
  
  .back-button i {
    font-size: 1.2em;
  }

</style>