<template>
  <div class="selection-page">
    <!-- Titel -->
    <div class="logo-container">
      <a href="https://spottedhsk.de" target="_self">
        <img src="https://spottedhsk.de/images/logo.png" alt="spottedHSK Logo" class="logo">
      </a>
    </div>

    <!-- Anfrage-Kategorie -->
    <div class="button-group">
      <router-link to="/spot-create">
        <button class="action-button">
          <div class="icon-circle">
            <i class="icon fas fa-search"></i>
          </div>
          <span class="button-text">Spot erstellen</span>
        </button>
      </router-link>
      <router-link to="/spot-request">
        <button class="action-button">
          <div class="icon-circle">
            <i class="icon fas fa-user-check"></i>
          </div>
          <span class="button-text">Kontaktherstellung</span>
        </button>
      </router-link>
      <router-link to="/block-plate">
        <button class="action-button">
          <div class="icon-circle">
            <i class="icon fas fa-ban"></i>
          </div>
          <span class="button-text">Kennzeichensperrung</span>
        </button>
      </router-link>
      <hr class="divider">
      <router-link to="/spots">
        <button class="action-button">
          <div class="icon-circle">
            <i class="icon fas fa-list"></i>
          </div>
          <span class="button-text">Übersicht der Spots</span>
        </button>
      </router-link>
    </div>



    <!-- Social Media Icons -->
    <div class="social-media">
      <a href="https://instagram.com/spottedhsk" target="_blank" class="social-icon">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="https://threads.net/spottedhsk" target="_blank" class="social-icon">
        <i class="fas fa-hashtag"></i>
      </a>
      <a href="https://buymeacoffee.com/spottedhsk" target="_blank" class="social-icon2">
        <i class="fas fa-heart"></i>
      </a>
      <a href="https://snapchat.com/add/spottedhsk" target="_blank" class="social-icon">
        <i class="fab fa-snapchat"></i>
      </a>
      <a href="mailto:support@spottedhsk.de" class="social-icon">
        <i class="fas fa-envelope"></i>
      </a>
    </div>

    <!-- Footer mit Regeln zum Einreichen -->
    <footer class="footer">
      <router-link to="/rules" class="footer-link">
        Regeln zum Einreichen von Anfragen
      </router-link>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'SelectionPage',
};
</script>

<style scoped>
.selection-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #121212;
  color: #ffffff;
  text-align: center;
  padding: 20px;
  animation: fadeIn 1s ease-in-out forwards;
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: auto; /* Keine Breite vom Logo übernehmen */
  align-items: center;
}


.divider {
  width: 320px; /* Gleiche Breite wie die Buttons */
  margin: 20px auto; /* Zentriert den Divider */
  height: 1px; /* Höhe der Linie */
  background: #555555; /* Farbe der Linie */
  opacity: 0.7; /* Transparenz */
}


@media (max-width: 768px) {
  .logo {
    width: 300px; /* Reduzierte Breite für Tablets */
  }
}

@media (max-width: 480px) {
  .logo {
    width: 350px; /* Reduzierte Breite für Smartphones */
  }
}
.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 60px;
  padding: 0 20px;
  font-size: 1.1em;
  background-color: #1f1f1f;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.action-button:active {
  transform: translateY(1px);
}

.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.icon {
  color: #ffffff;
  font-size: 1.4em;
}

.button-text {
  flex: 1;
  color: #ffffff;
  text-align: left;
  white-space: nowrap;
}



.divider {
  width: 90%;
  margin: 0px auto;
  border: 0;
  height: 1px;
  background: #555555;
  opacity: 0.7;
}



.social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.social-icon {
  font-size: 1.8em;
  color: #ffffff;
  transition: color 0.3s ease;
  cursor: pointer;
}

.social-icon:hover {
  color: #00cc66;
}

.social-icon2 {
  font-size: 1.8em;
  color: red;
  transition: color 0.3s ease;
  cursor: pointer;
}

/* Footer für Regeln zum Einreichen */
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 10px 0;
  text-align: center;
  background-color: #121212;
  font-size: 0.9em;
}

.footer-link {
  color: #4ade80;
  text-decoration: none;
  padding: 5px 15px;
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
}

.footer-link:hover {
  border-color: #4ade80;
  border-radius: 5px;
  background-color: #1f1f1f;
}


/* Entfernt die Unterstreichung für Links */
a, .action-button, .social-icon, .back-button {
  text-decoration: none;
  color: inherit;
}


.logo {
  max-width: 550px; /* Maximale Breite des Logos */
  height: auto; /* Beibehaltung des Seitenverhältnisses */
  display: block; /* Stellt sicher, dass das Logo ein Blockelement ist */
  margin: 0 auto; /* Zentriert das Logo */
}

@media (max-width: 768px) {
  .logo-container {
    margin-top: -85px; /* Weniger Abstand auf Tablets */
  }
}

</style>
